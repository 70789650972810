import type { Doctor, HospitalCampus, HospitalDepartment } from './hospital.ts'
import type { Patient } from './patient.ts'
import type { HospitalizationRecord } from './hospitalization.ts'

export interface OutpatientTime {
  id: number
  department: HospitalDepartment
  campus?: HospitalCampus
  enabled: boolean
  doctorType: string
  name: string
  startTime: number
  endTime: number
  date: number
  capacity: number
  price: number
  createdAt?: string
  updatedAt?: string
}

export interface AvailableOutpatientTime {
  id: number
  department: HospitalDepartment
  campus?: HospitalCampus
  name: string
  date: string
  startTime: number
  endTime: number
  available: number
  total: number
  price: number
}

export interface OutpatientRegistration {
  id: number
  time: OutpatientTime
  patient: Patient
  date: string
  status: OutpatientRegistrationStatus
  record?: Omit<OutpatientRecord, 'registration'>
}

export enum OutpatientRegistrationStatus {
  REGISTERED = 'REGISTERED',
  VISITED = 'VISITED',
  CANCELED = 'CANCELED'
}

export interface OutpatientRecord {
  id: number
  doctor: Doctor
  patient: Patient
  registration: Omit<OutpatientRegistration, 'record'>
  priceTotal: number
  diagnoses: OutpatientDiagnosis[]
  prescriptions: OutpatientPrescription[]
  examinations: OutpatientExamination[]
  hospitalizationRecord: Omit<HospitalizationRecord, 'record'>
}

export interface OutpatientDiagnosis {
  id: number
  description?: string
  diagnosis: string
}

export interface OutpatientPrescription {
  id: number
  priceTotal: number
  medicines: OutpatientMedicine[]
}

export interface OutpatientMedicine {
  id: number
  type?: string
  name: string
  price: number
  quantity: number
  advice?: string
}

export interface OutpatientExamination {
  id: number
  type: string
  content?: string
  price: number
}

export interface UpdateOutpatientTimeRequest {
  campus?: number
  department: number
  doctorType: string
  name: string
  startTime: number
  endTime: number
  date: number
  capacity: number
  price: number
}

export interface OutpatientRegisterRequest {
  patient: number
  time: number
  date: string
}
