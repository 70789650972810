<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { KeyOutlined, UserOutlined } from '@ant-design/icons-vue'

import { useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'
import LayoutAuth from '../layouts/LayoutAuth.vue'
import type { LoginRequest, TokenResponse } from '../types/user.ts'

const store = useStore()

const router = useRouter()

const form = ref<LoginRequest>({
  username: '',
  password: ''
})

const {
  data,
  error,
  isFetching,
  execute
} = useMyFetch<TokenResponse>('/api/token', {
  immediate: false
}).post(() => {
  return new URLSearchParams(form.value)
}).json()

const handleSubmit = () => {
  execute(true).then(() => {
    store.setToken(data.value)
    message.success('登录成功')
    router.push(sessionStorage.getItem('intendedUrl') || '/portal')
    sessionStorage.removeItem('intendedUrl')
  }, (error) => {
    console.error(error)
    message.error('登录失败')
  })
}
</script>

<template>
  <layout-auth
    title="登录"
    :actions="[
      {
        label: '忘记密码',
        to: '/reset-password'
      },
      {
        label: '注册',
        to: '/register'
      }
    ]"
    :loading="isFetching"
    @submit="handleSubmit"
  >
    <div class="max-w-md w-full mx-auto bg-white sm:bg-transparent pb-4">
      <a-alert
        type="info"
        message="演示系统"
        description="默认系统管理员用户名和密码均为“admin”；默认医院管理员用户名和密码均为“hospital”"
        show-icon
      />
    </div>
    <a-form
      :model="form"
      layout="vertical"
    >
      <a-form-item
        name="username"
        label="用户名"
        :validate-status="error ? 'error' : ''"
      >
        <a-input
          v-model:value="form.username"
          placeholder="请输入用户名"
          autocomplete="username"
          @pressEnter="handleSubmit"
        >
          <template #prefix>
            <user-outlined />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item
        name="password"
        label="密码"
        :validate-status="error ? 'error' : ''"
        :help="error ? '用户名或密码错误' : ''"
      >
        <a-input-password
          v-model:value="form.password"
          placeholder="请输入密码"
          autocomplete="current-password"
          @pressEnter="handleSubmit"
        >
          <template #prefix>
            <key-outlined />
          </template>
        </a-input-password>
      </a-form-item>
    </a-form>
  </layout-auth>
</template>
