export const daysOfWeek: Record<number, string> = {
  1: '星期一',
  2: '星期二',
  3: '星期三',
  4: '星期四',
  5: '星期五',
  6: '星期六',
  7: '星期日'
}

export const formatDayOfWeek = (value: number): string => {
  return daysOfWeek[value]
}

export const formatTimeOfDay = (value: number, seconds?: boolean): string => {
  return (seconds ? [
    Math.floor(value / 3600),
    Math.floor((value % 3600) / 60),
    value % 60
  ] : [
    Math.floor(value / 60),
    value % 60
  ]).map((v) => {
    return v.toString().padStart(2, '0')
  }).join(':')
}
