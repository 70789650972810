<script setup lang="ts">
import { computed, watchEffect } from 'vue'
import type { SelectProps } from 'ant-design-vue'

import { useMyFetch } from '../fetch.ts'
import type { Patient } from '../types/patient.ts'

const modelValue = defineModel<number>({
  default: 0
})

const {
  data
} = useMyFetch('/api/user/patients')
  .json<Patient[]>()

const options = computed<NonNullable<SelectProps['options']>>(() => {
  const result = data.value?.map((v) => ({
    label: v.name,
    value: v.id
  })) ?? []
  return result.length ? result : [{
    value: 0,
    label: '暂无就诊人信息'
  }]
})

watchEffect(() => {
  if (!options.value.some((o) => o.value === modelValue.value)) {
    modelValue.value = options.value[0].value as number
  }
})
</script>

<template>
  <a-select
    v-model:value="modelValue"
    :options="options"
    placeholder="请选择就诊人信息"
  />
</template>
