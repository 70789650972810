import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import { message } from 'ant-design-vue'

import { useStore } from './store.ts'
import LayoutPortal from './layouts/LayoutPortal.vue'
import PageAbout from './pages/PageAbout.vue'
import PageAdminHospitals from './pages/PageAdminHospitals.vue'
import PageAdminUsers from './pages/PageAdminUsers.vue'
import PageHospitalBeds from './pages/PageHospitalBeds.vue'
import PageHospitalCampuses from './pages/PageHospitalCampuses.vue'
import PageHospitalDepartments from './pages/PageHospitalDepartments.vue'
import PageHospitalDoctors from './pages/PageHospitalDoctors.vue'
import PageHospitalInfo from './pages/PageHospitalInfo.vue'
import PageHospitalNurses from './pages/PageHospitalNurses.vue'
import PageLogin from './pages/PageLogin.vue'
import PageOutpatientAdmission from './pages/PageOutpatientAdmission.vue'
import PageOutpatientRecord from './pages/PageOutpatientRecord.vue'
import PageOutpatientRecords from './pages/PageOutpatientRecords.vue'
import PageOutpatientRegistrations from './pages/PageOutpatientRegistrations.vue'
import PageOutpatientTimes from './pages/PageOutpatientTimes.vue'
import PagePatientPatients from './pages/PagePatientPatients.vue'
import PagePatientRegister from './pages/PagePatientRegister.vue'
import PagePortal from './pages/PagePortal.vue'
import PageRegister from './pages/PageRegister.vue'
import PageResetPassword from './pages/PageResetPassword.vue'
import PageUpdateQuestions from './pages/PageUpdateQuestions.vue'

declare module 'vue-router' {
  interface RouteMeta {
    title?: string
    subTitle?: string
    auth?: string | string[] | boolean
    selectHospital?: boolean
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/portal'
  },
  {
    path: '/login',
    component: PageLogin
  },
  {
    path: '/portal',
    component: LayoutPortal,
    children: [
      {
        path: '',
        component: PagePortal
      },
      {
        path: 'about',
        component: PageAbout,
        meta: {
          title: '说明'
        }
      },
      {
        path: 'admin/hospitals',
        component: PageAdminHospitals,
        meta: {
          title: '医院管理',
          auth: 'admin'
        }
      },
      {
        path: 'admin/users',
        component: PageAdminUsers,
        meta: {
          title: '用户管理',
          auth: 'admin'
        }
      },
      {
        path: 'hospital',
        component: PageHospitalInfo,
        meta: {
          title: '医院基本信息',
          auth: 'hospital_admin',
          selectHospital: true
        }
      },
      {
        path: 'hospital/campuses',
        component: PageHospitalCampuses,
        meta: {
          title: '医院院区管理',
          auth: 'hospital_admin',
          selectHospital: true
        }
      },
      {
        path: 'hospital/departments',
        component: PageHospitalDepartments,
        meta: {
          title: '医院科室管理',
          auth: 'hospital_admin',
          selectHospital: true
        }
      },
      {
        path: 'hospital/doctors',
        component: PageHospitalDoctors,
        meta: {
          title: '医院医生管理',
          auth: 'hospital_admin',
          selectHospital: true
        }
      },
      {
        path: 'hospital/nurses',
        component: PageHospitalNurses,
        meta: {
          title: '医院护士管理',
          auth: 'hospital_admin',
          selectHospital: true
        }
      },
      {
        path: 'hospitalization/beds',
        component: PageHospitalBeds,
        meta: {
          title: '医院床位管理',
          auth: ['hospital_admin', 'doctor', 'nurse'],
          selectHospital: true
        }
      },
      {
        path: 'outpatient/admission',
        component: PageOutpatientAdmission,
        meta: {
          title: '门诊接诊',
          auth: 'doctor'
        }
      },
      {
        path: 'outpatient/records',
        component: PageOutpatientRecords,
        meta: {
          title: '门诊记录',
          auth: ['hospital_admin', 'doctor'],
          selectHospital: true
        }
      },
      {
        path: 'outpatient/records/:id',
        component: PageOutpatientRecord,
        meta: {
          title: '门诊记录',
          auth: true
        }
      },
      {
        path: 'outpatient/registrations',
        component: PageOutpatientRegistrations,
        meta: {
          title: '挂号记录',
          auth: ['hospital_admin', 'doctor'],
          selectHospital: true
        }
      },
      {
        path: 'outpatient/times',
        component: PageOutpatientTimes,
        meta: {
          title: '出诊时段管理',
          auth: 'hospital_admin',
          selectHospital: true
        }
      },
      {
        path: 'patient/outpatient-records',
        component: PageOutpatientRecords,
        meta: {
          title: '就诊记录',
          auth: 'patient'
        }
      },
      {
        path: 'patient/patients',
        component: PagePatientPatients,
        meta: {
          title: '就诊人信息',
          auth: 'patient'
        }
      },
      {
        path: 'patient/register',
        component: PagePatientRegister,
        meta: {
          title: '在线挂号',
          auth: 'patient'
        }
      },
      {
        path: 'patient/registrations',
        component: PageOutpatientRegistrations,
        meta: {
          title: '挂号记录',
          auth: 'patient'
        }
      }
    ]
  },
  {
    path: '/register',
    component: PageRegister
  },
  {
    path: '/reset-password',
    component: PageResetPassword
  },
  {
    path: '/update-questions',
    component: PageUpdateQuestions,
    meta: {
      auth: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async () => {
  const store = useStore()

  if (store.token && !store.user) {
    await store.fetchUser()
  }

  return true
})

router.beforeEach(async (to) => {
  const store = useStore()

  if (to.meta?.auth) {
    // requires authorization

    if (!store.user) {
      // not logged in, go to login page
      sessionStorage.setItem('intendedUrl', to.fullPath)
      return '/login'
    }

    if (typeof to.meta.auth !== 'boolean' && !store.hasRole(to.meta.auth)) {
      // logged in, but not authorized
      message.warn('你没有权限访问此页面')
      return '/portal'
    }
  }

  return true
})

export default router
