<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { FormInstance } from 'ant-design-vue'
import { message, Modal } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'

import { myFetchToPromise, useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'
import InputMoney from '../components/InputMoney.vue'
import SelectCampus from '../components/SelectCampus.vue'
import SelectDepartment from '../components/SelectDepartment.vue'
import type { HospitalBedWithUsage, UpdateHospitalBedRequest } from '../types/hospitalization.ts'
import CardBedInfo from '../components/CardBedInfo.vue'

const store = useStore()

const hospitalId = computed<string>(() => {
  return store.hospital?.id.toString() ?? ''
})

const isHospitalAdmin = computed<boolean>(() => {
  return store.hasRole('hospital_admin')
})

const {
  execute,
  data,
  isFetching
} = useMyFetch(() => {
  return `/api/hospitals/${hospitalId.value}/beds`
}, {
  refetch: true
}).json<HospitalBedWithUsage[]>()

const campuses = computed<Record<number, string>>(() => {
  const result = {} as Record<number, string>
  data.value?.forEach((item) => {
    if (!item.bed.campus && !result[0]) {
      result[0] = '无院区属性'
    } else if (item.bed.campus && !result[item.bed.campus.id]) {
      result[item.bed.campus.id] = item.bed.campus.name
    }
  })
  if (!data.value?.length) {
    result[0] = '无院区属性'
  }
  return result
})

const campusId = ref<number>(0)

watch(campuses, (v) => {
  const defaultKey = Number(Object.keys(v)[0])
  if (campusId.value !== defaultKey && !v[campusId.value]) {
    campusId.value = defaultKey
  }
}, {
  immediate: true
})

const departments = computed<Record<number, string>>(() => {
  const result = {} as Record<number, string>
  data.value?.forEach((item) => {
    if (item.bed.department && !result[item.bed.department.id]) {
      result[item.bed.department.id] = item.bed.department.name
    }
  })
  return result
})

const dataCategorized = computed<Record<number, Record<number, HospitalBedWithUsage[]>>>(() => {
  const result = {} as Record<number, Record<number, HospitalBedWithUsage[]>>
  data.value?.forEach((item) => {
    const campusId = item.bed.campus?.id || 0
    if (!result[campusId]) {
      result[campusId] = {}
    }
    const departmentId = item.bed.department?.id || 0
    if (!result[campusId][departmentId]) {
      result[campusId][departmentId] = []
    }
    result[campusId][departmentId].push(item)
  })
  return result
})

const modalEditOpen = ref(false)

const editingId = ref<number | null>(null)

const formEditRef = ref<FormInstance | null>(null)

const formEdit = ref<Required<UpdateHospitalBedRequest>>({
  campus: 0,
  department: 0,
  type: '',
  name: '',
  price: 0
})

const handleCreate = () => {
  modalEditOpen.value = true
  editingId.value = null
  formEdit.value = {
    campus: 0,
    department: 0,
    type: '',
    name: '',
    price: 0
  }
}

const handleEdit = (id: number) => {
  editingId.value = id
  useMyFetch(`/api/beds/${id}`).json<HospitalBedWithUsage>().then(({ data }) => {
    if (data.value) {
      formEdit.value = {
        campus: data.value.bed.campus?.id || 0,
        department: data.value.bed.department?.id || 0,
        type: data.value.bed.type || '',
        name: data.value.bed.name,
        price: data.value.bed.price
      }
      modalEditOpen.value = true
    }
  })
}

const handleSubmitEdit = async () => {
  await formEditRef.value?.validate()
  try {
    if (editingId.value === null) {
      await myFetchToPromise(useMyFetch(`/api/hospitals/${hospitalId.value}/beds`)
        .post(formEdit.value))
    } else {
      await myFetchToPromise(useMyFetch(`/api/beds/${editingId.value}`)
        .put(formEdit.value))
    }
    await execute(true)
    message.success('保存成功')
    modalEditOpen.value = false
  } catch (error) {
    console.error(error)
    message.error('保存失败')
  }
}

const handleCancelEdit = () => {
  formEditRef.value?.clearValidate()
}

const handleDelete = (id: number) => {
  Modal.confirm({
    title: '注意',
    content: '确定要删除此床位吗？所有关联的记录都将被一并删除',
    onOk: async () => {
      await myFetchToPromise(useMyFetch(`/api/beds/${id}`)
        .delete())
      await execute(true)
      message.success('删除成功')
    }
  })
}
</script>

<template>
  <a-tabs v-model:activeKey="campusId">
    <a-tab-pane
      v-for="(name, id) in campuses"
      :key="Number(id)"
      :tab="name"
    />
    <template #leftExtra>
      <div
        v-if="isHospitalAdmin"
        class="pr-4"
      >
        <a-button
          type="primary"
          @click="handleCreate"
        >
          <plus-outlined />
          添加床位
        </a-button>
      </div>
    </template>
  </a-tabs>
  <div
    v-if="isFetching"
    class="flex items-center justify-center py-8"
  >
    <a-spin size="large" />
  </div>
  <div v-else-if="dataCategorized[campusId] && Object.keys(dataCategorized[campusId]).length">
    <template
      v-for="(departmentData, departmentId) in dataCategorized[campusId]"
      :key="departmentId"
    >
      <div
        v-if="departments[departmentId]"
        class="flex items-center gap-3 my-4"
      >
        <h5
          class="text-sm font-semibold m-0"
          v-text="departments[departmentId]"
        />
        <div class="flex-1 h-[1px] bg-gray-200" />
      </div>
      <div class="grid grid-cols-3 lg:grid-cols-4 gap-4">
        <card-bed-info
          v-for="item of departmentData"
          :key="item.bed.id"
          :value="item"
          :admin="isHospitalAdmin"
          class="justify-between"
          @edit="handleEdit(item.bed.id)"
          @delete="handleDelete(item.bed.id)"
        />
      </div>
    </template>
  </div>
  <div
    v-else
    class="flex items-center justify-center py-8"
  >
    <a-empty />
  </div>
  <a-modal
    v-if="isHospitalAdmin"
    v-model:open="modalEditOpen"
    :title="editingId ? '编辑床位' : '添加床位'"
    ok-text="提交"
    @cancel="handleCancelEdit"
    @ok="handleSubmitEdit"
  >
    <a-form
      ref="formEditRef"
      :model="formEdit"
      layout="vertical"
    >
      <a-form-item
        label="所属院区"
        name="campus"
        :rules="[{ type: 'number', min: 0, message: '请选择床位所属院区' }]"
      >
        <select-campus
          v-model="formEdit.campus"
          allow-empty
        />
      </a-form-item>
      <a-form-item
        label="所属科室"
        name="department"
        :rules="[{ type: 'number', min: 0, message: '请选择床位所属科室' }]"
      >
        <select-department
          v-model="formEdit.department"
          allow-empty
        />
      </a-form-item>
      <a-form-item
        label="床位类型"
        name="type"
        extra="普通病房，ICU 病房等"
      >
        <a-input
          v-model:value="formEdit.type"
          :maxlength="80"
        />
      </a-form-item>
      <a-form-item
        label="床位名称"
        name="name"
        :rules="[{ required: true, message: '请输入床位名称' }]"
      >
        <a-input
          v-model:value="formEdit.name"
          :maxlength="80"
        />
      </a-form-item>
      <a-form-item
        label="床位价格（每天）"
        name="price"
        :rules="[{ required: true, type: 'number', min: 0, message: '请输入有效的价格' }]"
      >
        <input-money v-model="formEdit.price" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
