<script setup lang="ts">
import { useRouter } from 'vue-router'
import { formatDate } from '@vueuse/shared'
import { message, Modal } from 'ant-design-vue'

import { myFetchToPromise, useMyFetch } from '../fetch.ts'
import { formatTimeOfDay } from '../utils/time.ts'
import type { OutpatientRecord, OutpatientRegistration } from '../types/outpatient.ts'

const router = useRouter()

const {
  data,
  execute,
  isFetching
} = useMyFetch('/api/outpatient/registrations/available-admissions')
  .json<OutpatientRegistration[]>()

const handleAdmission = (id: number) => {
  Modal.confirm({
    title: '注意',
    content: '确认接诊该患者？',
    onOk: async () => {
      try {
        const data = await myFetchToPromise(useMyFetch(`/api/outpatient/registrations/${id}/admission`)
          .post()
          .json<OutpatientRecord>())
        Modal.confirm({
          title: '接诊成功',
          content: '请前往门诊记录填写诊断、处方等信息',
          okText: '查看门诊记录',
          cancelText: '关闭',
          onOk: () => {
            if (data?.id) {
              router.push(`/portal/outpatient/records/${data.id}`)
            } else {
              router.push('/portal/outpatient/records')
            }
          }
        })
        execute()
      } catch (error) {
        message.error('接诊失败')
      }
    }
  })
}
</script>

<template>
  <a-table
    :columns="[
      {
        title: '院区',
        dataIndex: ['time', 'campus', 'name']
      },
      {
        key: 'name',
        title: '号别'
      },
      {
        key: 'time',
        title: '时间'
      },
      {
        title: '就诊人',
        dataIndex: ['patient', 'name']
      },
      {
        title: '性别',
        dataIndex: ['patient', 'gender']
      },
      {
        title: '年龄',
        dataIndex: ['patient', 'age']
      },
      {
        key: 'actions',
        align: 'right'
      }
    ]"
    row-key="id"
    :data-source="data"
    :loading="isFetching"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'name'">
        {{ record.time.doctorType }} - {{ record.time.name }}
      </template>
      <template v-else-if="column.key === 'time'">
        {{ formatDate(new Date(record.date), 'M月D日') }}
        {{ formatTimeOfDay(record.time.startTime) }}-{{ formatTimeOfDay(record.time.endTime) }}
      </template>
      <template v-else-if="column.key === 'actions'">
        <a-space>
          <a-button
            type="link"
            small
            @click="handleAdmission(record.id)"
          >
            接诊
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
</template>
