<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { formatDate } from '@vueuse/shared'
import type { TableProps } from 'ant-design-vue'
import { message, Modal } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'

import { myFetchToPromise, useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'
import { formatTimeOfDay } from '../utils/time.ts'
import type { OutpatientRegistration } from '../types/outpatient.ts'
import { OutpatientRegistrationStatus } from '../types/outpatient.ts'
import OutpatientRegistrationStatusText from '../components/OutpatientRegistrationStatusText.vue'

const store = useStore()

const router = useRouter()

const isPatient = computed<boolean>(() => {
  return store.hasRole('patient')
})

const hospitalId = computed<string>(() => {
  return store.hospital?.id.toString() ?? ''
})

const {
  data,
  execute,
  isFetching
} = useMyFetch(() => {
  if (isPatient.value) {
    return '/api/user/outpatient/registrations'
  } else {
    return `/api/hospitals/${hospitalId.value}/outpatient/registrations`
  }
}, {
  refetch: true
}).json<OutpatientRegistration[]>()

const columns = computed<NonNullable<TableProps['columns']>>(() => {
  const res = [] as NonNullable<TableProps['columns']>
  if (isPatient.value) {
    res.push({
      title: '医院',
      dataIndex: ['time', 'hospital', 'name']
    })
  }
  res.push({
    title: '院区',
    dataIndex: ['time', 'campus', 'name']
  }, {
    title: '科室',
    dataIndex: ['time', 'department', 'name']
  })
  if (isPatient.value) {
    res.push({
      key: 'time',
      title: '时间'
    })
  } else {
    res.push({
      key: 'date',
      title: '日期'
    })
  }
  res.push({
    key: 'name',
    title: '号别'
  }, {
    title: '就诊人',
    dataIndex: ['patient', 'name']
  })
  if (!isPatient.value) {
    res.push({
      title: '性别',
      dataIndex: ['patient', 'gender']
    }, {
      title: '年龄',
      dataIndex: ['patient', 'age']
    })
  }
  res.push({
    key: 'status',
    title: '状态'
  }, {
    key: 'actions',
    align: 'right'
  })
  return res
})

const handleRegister = () => {
  router.push('/portal/patient/register')
}

const handleCancel = (id: number) => {
  Modal.confirm({
    title: '注意',
    content: '确定要取消本次挂号吗？',
    onOk: async () => {
      await myFetchToPromise(useMyFetch(() => {
        if (isPatient.value) {
          return `/api/user/outpatient/registrations/${id}`
        } else {
          return `/api/outpatient/registrations/${id}`
        }
      }).delete())
      await execute(true)
      message.success('取消成功')
    }
  })
}

const handleViewRecord = (id: number) => {
  router.push(`/portal/outpatient/records/${id}`)
}
</script>

<template>
  <div
    v-if="isPatient"
    class="mb-4"
  >
    <a-button
      type="primary"
      @click="handleRegister"
    >
      <plus-outlined />
      在线挂号
    </a-button>
  </div>
  <a-table
    :columns="columns"
    row-key="id"
    :data-source="data"
    :loading="isFetching"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'name'">
        {{ record.time.doctorType }} - {{ record.time.name }}
      </template>
      <template v-else-if="column.key === 'date'">
        {{ formatDate(new Date(record.date), 'M月D日') }}
      </template>
      <template v-else-if="column.key === 'time'">
        {{ formatDate(new Date(record.date), 'M月D日') }}
        {{ formatTimeOfDay(record.time.startTime) }}-{{ formatTimeOfDay(record.time.endTime) }}
      </template>
      <template v-else-if="column.key === 'status'">
        <outpatient-registration-status-text :value="record.status" />
      </template>
      <template v-else-if="column.key === 'actions'">
        <a-space>
          <a-button
            v-if="record.status === OutpatientRegistrationStatus.REGISTERED"
            type="link"
            danger
            size="small"
            @click="handleCancel(record.id)"
          >
            取消
          </a-button>
          <a-button
            v-if="record.record"
            type="link"
            size="small"
            @click="handleViewRecord(record.record.id)"
          >
            查看就诊记录
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
</template>
