<script setup lang="ts" xmlns="http://www.w3.org/1999/html">
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { formatDate } from '@vueuse/shared'
import type { FormInstance } from 'ant-design-vue'
import { message, Modal } from 'ant-design-vue'
import { CheckOutlined, CloseOutlined, PhoneOutlined } from '@ant-design/icons-vue'

import { myFetchToPromise, useMyFetch } from '../fetch.ts'
import { formatMoney } from '../utils/money.ts'
import { formatTimeOfDay } from '../utils/time.ts'
import CardHospitalInfo from '../components/CardHospitalInfo.vue'
import SelectPatient from '../components/SelectPatient.vue'
import type { HospitalCampus, HospitalDepartment, HospitalWithoutOwner } from '../types/hospital.ts'
import type { AvailableOutpatientTime, OutpatientRegisterRequest } from '../types/outpatient.ts'

const router = useRouter()

const {
  data: hospitals,
  isFetching: isFetchingHospitals
} = useMyFetch('/api/user/hospitals')
  .json<HospitalWithoutOwner[]>()

const selectedHospital = ref<number>(0)

watch(hospitals, (v) => {
  if (v?.length === 1) {
    selectedHospital.value = v[0].id
  } else {
    selectedHospital.value = 0
  }
}, {
  immediate: true
})

const {
  data,
  execute,
  isFetching
} = useMyFetch(() => `/api/user/hospitals/${selectedHospital.value}/outpatient/available-times`, {
  immediate: false,
  refetch: false
}).json<AvailableOutpatientTime[]>()

watch(selectedHospital, (v) => {
  if (v) {
    execute()
  } else {
    data.value = null
  }
}, {
  immediate: true
})

const campuses = computed<Record<number, HospitalCampus | null>>(() => {
  const result = {} as Record<number, HospitalCampus | null>
  data.value?.forEach((item) => {
    if (!item.campus && !result[0]) {
      result[0] = null
    } else if (item.campus && !result[item.campus.id]) {
      result[item.campus.id] = item.campus
    }
  })
  return result
})

const selectedCampus = ref<number>(0)

watch(campuses, (v) => {
  if (!Object.keys(v).length) {
    selectedCampus.value = 0
  }
  const defaultKey = Number(Object.keys(v)[0])
  if (selectedCampus.value !== defaultKey && !v[selectedCampus.value]) {
    selectedCampus.value = defaultKey
  }
}, {
  immediate: true
})

const departments = computed<Record<number, HospitalDepartment>>(() => {
  const result = {} as Record<number, HospitalDepartment>
  data.value?.forEach((item) => {
    if (!result[item.department.id]) {
      result[item.department.id] = item.department
    }
  })
  return result
})

const selectedDepartment = ref<number>(0)

watch(departments, (v) => {
  if (!Object.keys(v).length) {
    selectedDepartment.value = 0
  }
  const defaultKey = Number(Object.keys(v)[0])
  if (selectedDepartment.value !== defaultKey && !v[selectedDepartment.value]) {
    selectedDepartment.value = defaultKey
  }
}, {
  immediate: true
})

const dataCategorized = computed<Record<string, AvailableOutpatientTime[]>>(() => {
  const result = {} as Record<string, AvailableOutpatientTime[]>
  data.value?.forEach((item) => {
    if ((item.campus?.id || 0) !== selectedCampus.value) {
      return
    }
    if (item.department.id !== selectedDepartment.value) {
      return
    }
    if (!result[item.date]) {
      result[item.date] = []
    }
    result[item.date].push(item)
  })
  return result
})

const selectedDate = ref<string>('')

watch(dataCategorized, (v) => {
  if (!Object.keys(v).length) {
    selectedDate.value = ''
  }
  const defaultKey = Object.keys(v)[0]
  if (selectedDate.value !== defaultKey && !v[selectedDate.value]) {
    selectedDate.value = defaultKey
  }
}, {
  immediate: true
})

const modalRegisterOpen = ref(false)

const formRegisterRef = ref<FormInstance | null>(null)

const formRegister = ref<OutpatientRegisterRequest>({
  time: 0,
  patient: 0,
  date: ''
})

const handleRegister = (id: number, date: string) => {
  formRegister.value = {
    time: id,
    patient: 0,
    date
  }
  modalRegisterOpen.value = true
}

const handleSubmitRegister = async () => {
  await formRegisterRef.value?.validate()
  try {
    await myFetchToPromise(useMyFetch('/api/user/outpatient/registrations')
      .post(formRegister.value))
    message.success('挂号成功')
    await execute(true)
    modalRegisterOpen.value = false
  } catch (error: any) {
    console.error(error)
    if (error.response?.status === 409) {
      Modal.confirm({
        title: '注意',
        content: '此就诊人在此时段已有挂号信息，请前往挂号记录页面查看',
        okText: '查看挂号记录',
        cancelText: '关闭',
        onOk: () => {
          modalRegisterOpen.value = false
          router.push('/portal/patient/registrations')
        },
        onCancel: () => {
          modalRegisterOpen.value = false
        }
      })
    } else {
      message.error('挂号失败')
    }
  }
}

const handleCancelRegister = () => {
  formRegisterRef.value?.clearValidate()
}

const selectedTime = computed<AvailableOutpatientTime | null>(() => {
  if (formRegister.value.time && formRegister.value.date) {
    return data.value?.find((item) => {
      return item.id === formRegister.value.time && item.date === formRegister.value.date
    }) ?? null
  } else {
    return null
  }
})
</script>

<template>
  <div
    v-if="hospitals && selectedHospital"
    class="flex flex-col items-stretch gap-4"
  >
    <template
      v-for="hospital of hospitals"
      :key="hospital.id"
    >
      <card-hospital-info
        v-if="hospital.id === selectedHospital"
        :value="hospital"
        class="bg-white"
      >
        <template #actions>
          <a-button
            type="primary"
            danger
            @click="selectedHospital = 0"
          >
            <close-outlined />
            重新选择
          </a-button>
        </template>
      </card-hospital-info>
    </template>
    <div
      v-if="data && data.length"
      class="flex gap-4"
    >
      <div class="flex flex-col items-stretch gap-4 w-48 flex-shrink-0">
        <div
          v-if="Object.keys(campuses).length > 1"
          class="bg-white rounded-lg p-2 space-y-1"
        >
          <div
            v-for="(campus, id) in campuses"
            :key="id"
            class="flex flex-col gap-1 px-4 py-2 rounded-lg select-none"
            :class="selectedCampus === Number(id) ? 'bg-blue-50' : 'hover:bg-gray-100 cursor-pointer'"
            @click="selectedCampus = Number(id)"
          >
            <h5
              class="m-0 text-base"
              :class="selectedCampus === Number(id) ? 'text-blue-600 font-medium' : 'text-gray-700 font-normal'"
              v-text="campus?.name || '无院区属性'"
            />
            <div
              v-if="campus?.phone"
              class="text-xs text-gray-500"
            >
              <phone-outlined />
              {{ campus?.phone }}
            </div>
          </div>
        </div>

        <div
          v-if="Object.keys(departments).length > 1"
          class="bg-white rounded-lg p-2 space-y-1"
        >
          <div
            v-for="(department, id) in departments"
            :key="id"
            class="flex flex-col gap-1 px-4 py-2 rounded-lg select-none"
            :class="selectedDepartment === Number(id) ? 'bg-blue-50' : 'hover:bg-gray-100 cursor-pointer'"
            @click="selectedDepartment = Number(id)"
          >
            <h5
              class="m-0 text-base"
              :class="selectedDepartment === Number(id) ? 'text-blue-600 font-medium' : 'text-gray-700 font-normal'"
              v-text="department.name"
            />
            <div
              v-if="department.phone"
              class="text-xs text-gray-500"
            >
              <phone-outlined />
              {{ department.phone }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="Object.keys(dataCategorized).length"
        class="flex-1 flex flex-col items-stretch gap-4"
      >
        <div class="flex bg-white rounded-lg p-2 space-x-1">
          <div
            v-for="(data, date) in dataCategorized"
            :key="date"
            class="flex-1 flex flex-col items-center gap-1 p-2 rounded-lg select-none"
            :class="selectedDate === date ? 'bg-blue-50' : 'hover:bg-gray-100 cursor-pointer'"
            @click="selectedDate = date"
          >
            <h5
              class="m-0 text-sm text-center"
              :class="selectedDate === date ? 'text-blue-600 font-medium' : 'text-gray-700 font-normal'"
              v-html="formatDate(new Date(date), 'M月D日<br>dddd')"
            />
            <span class="text-xs text-gray-500">
              余 {{ data.reduce((acc, cur) => acc + cur.available, 0) }}
            </span>
          </div>
        </div>

        <div
          v-if="selectedDate"
          v-for="item in dataCategorized[selectedDate]"
          :key="item.id"
          class="flex flex-col items-stretch bg-white gap-2 rounded-lg px-6 py-4"
        >
          <div class="flex items-center gap-2 text-xs text-gray-400">
            <template v-if="item.campus">
              <span v-text="item.campus.name" />
              <span>·</span>
            </template>
            <span v-text="item.department.name" />
            <span>·</span>
            <span v-text="formatDate(new Date(item.date), 'YYYY年M月D日')" />
            <span v-text="formatTimeOfDay(item.startTime) + '-' + formatTimeOfDay(item.endTime)" />
          </div>

          <div class="flex items-stretch bg-white gap-2">
            <div class="flex-1 flex flex-col gap-1 rounded-lg select-none">
              <h5 class="m-0 text-lg">
                {{ item.name }}
              </h5>
              <span class="text-sm text-gray-500">
                余 {{ item.available }}
              </span>
            </div>
            <div class="flex flex-col items-end gap-2">
              <span
                class="text-base text-amber-600"
                v-text="formatMoney(item.price)"
              />
              <a-button
                type="primary"
                :disabled="!item.available"
                @click="handleRegister(item.id, item.date)"
              >
                立即挂号
              </a-button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="flex-1 flex items-center justify-center py-8"
      >
        <a-empty />
      </div>
    </div>

    <div
      v-else
      class="flex items-center justify-center py-8"
    >
      <a-spin
        v-if="isFetching"
        size="large"
      />
      <a-empty v-else />
    </div>
  </div>
  <div
    v-else-if="hospitals"
    class="bg-white border border-gray-200 divide-y divide-gray-200 rounded-lg"
  >
    <card-hospital-info
      v-for="hospital of hospitals"
      :key="hospital.id"
      :value="hospital"
    >
      <template #actions>
        <a-button
          type="primary"
          @click="selectedHospital = hospital.id"
        >
          <check-outlined />
          在线挂号
        </a-button>
      </template>
    </card-hospital-info>
  </div>
  <div
    v-else
    class="flex items-center justify-center py-8"
  >
    <a-spin
      v-if="isFetchingHospitals"
      size="large"
    />
    <a-empty v-else />
  </div>
  <a-modal
    v-model:open="modalRegisterOpen"
    title="在线挂号"
    ok-text="提交"
    @cancel="handleCancelRegister"
    @ok="handleSubmitRegister"
  >
    <p v-if="selectedTime">
      <span>您已选择：</span>
      <strong>{{ formatDate(new Date(selectedTime.date), 'YYYY年M月D日') }}</strong>
      <strong>{{ formatTimeOfDay(selectedTime.startTime) }}-{{ formatTimeOfDay(selectedTime.endTime) }}</strong>
      <template v-if="selectedTime.campus">
        <span>在</span>
        <strong>{{ selectedTime.campus.name }}</strong>
      </template>
      <span>的</span>
      <strong>{{ selectedTime.department.name }}</strong>
      <span>号，请确认就诊人信息</span>
    </p>
    <a-form
      ref="formRegisterRef"
      :model="formRegister"
      layout="vertical"
    >
      <a-form-item
        label="就诊人信息"
        name="patient"
        :rules="[{ type: 'number', required: true, min: 1, message: '请选择就诊人信息' }]"
      >
        <select-patient v-model="formRegister.patient" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
