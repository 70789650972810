<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { formatDate } from '@vueuse/shared'

import { useStore } from '../store.ts'
import { useMyFetch } from '../fetch.ts'
import { formatMoney } from '../utils/money.ts'
import { OutpatientRecord } from '../types/outpatient.ts'
import type { TableProps } from 'ant-design-vue'

const store = useStore()

const router = useRouter()

const isPatient = computed<boolean>(() => {
  return store.hasRole('patient')
})

const isDoctor = computed<boolean>(() => {
  return store.hasRole('doctor')
})

const hospitalId = computed<string>(() => {
  return store.hospital?.id.toString() ?? ''
})

const {
  data,
  isFetching
} = useMyFetch(() => {
  if (isPatient.value) {
    return '/api/user/outpatient/records'
  } else if (isDoctor.value) {
    return '/api/outpatient/records'
  } else {
    return `/api/hospitals/${hospitalId.value}/outpatient/records`
  }
}, {
  refetch: true
}).json<OutpatientRecord[]>()

const columns = computed<NonNullable<TableProps['columns']>>(() => {
  const res = [] as NonNullable<TableProps['columns']>
  if (isPatient.value) {
    res.push({
      title: '医院',
      dataIndex: ['registration', 'time', 'hospital', 'name']
    })
  }
  res.push({
    title: '院区',
    dataIndex: ['registration', 'time', 'campus', 'name']
  })
  if (!isDoctor.value) {
    res.push({
      title: '科室',
      dataIndex: ['registration', 'time', 'department', 'name']
    })
  }
  res.push({
    key: 'registration-name',
    title: '号别'
  })
  if (!isDoctor.value) {
    res.push({
      title: '医生',
      dataIndex: ['doctor', 'name']
    })
  }
  res.push({
    title: '就诊人',
    dataIndex: ['patient', 'name']
  }, {
    key: 'price',
    title: '总价'
  }, {
    key: 'time',
    title: '日期'
  }, {
    key: 'actions',
    align: 'right'
  })
  return res
})

const handleViewRecord = (id: number) => {
  router.push(`/portal/outpatient/records/${id}`)
}
</script>

<template>
  <a-table
    :columns="columns"
    row-key="id"
    :data-source="data"
    :loading="isFetching"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'registration-name'">
        {{ record.registration.time.doctorType }} - {{ record.registration.time.name }}
      </template>
      <template v-else-if="column.key === 'price'">
        {{ formatMoney(record.priceTotal) }}
      </template>
      <template v-else-if="column.key === 'time'">
        {{ formatDate(new Date(record.createdAt), 'YYYY年M月D日') }}
      </template>
      <template v-else-if="column.key === 'actions'">
        <a-space>
          <a-button
            type="link"
            size="small"
            @click="handleViewRecord(record.id)"
          >
            详情
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
</template>
